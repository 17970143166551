file-uploader,new-file-uploader {
    .file_input_container {
        align-items: center;
        background-color: var(--color-grey-xxlight);
        border: rems(3) dashed var(--color-teal);
        border-radius: radius(large);
        cursor: pointer;
        display: flex;
        flex: 0 1 auto;
        justify-content: center;
        margin-bottom: rems(20);
        min-height: rems(150);
        padding: rems(20);
        transition: all 0.5s ease-out;

        &.no_margin {
            margin-bottom: 0;
        }

        &.no_margin + .file_documents_container {
            margin-top: rems(20);
        }

        &.drag_over {
            background-color: var(--color-teal-xlight);
            border: rems(3) solid var(--color-teal);
            transition: all 0.25s ease-out;
        }

        .file_input {
            visibility: hidden;
            position: absolute;
        }

        label {
            align-items: center;
            display: flex;
            flex-direction: column;
            cursor: pointer;
            width: 100%;
            text-align: center;

            svg {
                fill: var(--color-teal);
            }
        }

        &--teal {
            background-color: var(--color-teal-xlight);
            border-color: var(--color-teal);

            &.drag_over {
                background-color: var(--color-teal-light);
                border: rems(3) solid var(--color-teal);
                transition: all 0.25s ease-out;
            }
        }

        &.small {
            min-height: 0;
            //margin-bottom: rems(8);
            label {
                flex-direction: row;
                justify-content: center;
                sprite-image {
                    img {
                        width: rems(36);
                        margin-right: rems(4);
                    }
                }
                strong {
                    padding-right: rems(5);
                }
            }
        }
    }

    .file_images_container {
        display: flex;
        margin-bottom: rems(20);

        .file_image {
            border-radius: radius(medium);
            overflow: hidden;
            margin-right: rems(20);
            max-height: rems(96);

            img {
                height: auto;
                max-height: rems(96);
                max-width: 100%;
            }
        }
    }

    .photo-gallery {
        display: flex;
        flex-wrap: wrap;
        margin-top: rems(20);
        padding: 0;
        margin-bottom: rems(10);

        li {
            align-items: center;
            border: 1px solid var(--color-grey-xlight);
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: rems(10);
            margin-bottom: rems(10);
            padding: rems(5);
            width: rems(90);
            //max-height: rems(71);

            img {
                display: block;
                height: 100%;
                width: 100%;
                //max-height: rems(71);
            }
        }
    }
}

repair-item, .compact__table {
    .file--uploader--body {
        display: block;
    }

    h4 + file-uploader {
        margin-top: 0;
    }
    h5 + file-uploader {
        margin-top: rems(10);
    }

    maintenance-view-issue {
        > file-uploader {
            padding-bottom: rems(20);
        }
    }
}


.file_input_container {
    /*padding: 0px;*/
    label {

    }

    + .drag_over {
        background: #aaa;
        border-color: #1BB3AF;
    }
}

.file_editor_container {
    img {
        max-width: 100%;
    }

    button {
        /*margin-right: 4px;*/
    }
}

.file_document_icon_editable {
    cursor: pointer;
}

.file_document_icon {
    //width: rems(72) !important;
    img {
        height: rems(72);
        width: rems(72);
        align-self: flex-start;
    }
}

.file_document_name input {
    margin-right: rems(16);
    margin-bottom: 0;
}

.file_document_drag {
    width: rems(32) !important;
    cursor: move;

    svg-icon {
        svg {
            vertical-align: middle;
            height: rems(30);
        }
    }
}


.file_documents_container {
    display: flex;
    flex-direction: column;
    margin-bottom: rems(20);

    .file_document_group {
        display: flex;
        flex-direction: column;

        + .file_document_group {
            border-top: 1px solid rgba(0,0,0,0.2);
            margin-top: rems(20);
            padding-top: rems(20);
        }
    }

    .file_document {
        align-items: flex-start;
        display: flex;
        flex: 1 0 100%;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }

    .file_document_group {
        background-color: white;
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: radius(large);
        padding: rems(20) rems(20) rems(10) rems(20);
    }

    .file_document_title {
        border-bottom: 1px solid rgba(0,0,0,0.2);
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        font-weight: 700;
        padding-bottom: rems(10);
        margin-bottom: rems(20);
        width: 100%;

        .file_document_actions {
            //padding-top: rems(12);
        }
    }

    .file_document-item {
        align-items: center;
        display: flex;
        flex: 1 0 100%;
        width: 100%;
        overflow: hidden;

        + .file_document-item {
            border-top: 1px solid rgba(0,0,0,0.1);
            margin-top: rems(20);
            padding-top: rems(20);
        }

        &.invalid {
            background: var(--color-warning-dark);
            padding-top: 0px;
            border-radius: radius(medium);
            color: var(--color-white);

            .file_document_icon {
                margin-top: 4px;
                margin-left: 8px;
                margin-right: 2px;
            }
        }
    }

    .file_document_icon {
        margin: 0 10px 10px 0;
        width: 36px;
        height: 36px;
        svg {
            height: 36px;
            width: 36px;
        }

        img {
            height: auto;
            width: 36px;
        }
    }

    .file_document-item {
        .file_document_icon {
            &.file_document_icon_isImage {
                display: flex;
                //margin-bottom: rems(10);
                margin-right: rems(10);
                width: rems(64);
                height: auto;

                img {
                    display: block;
                    height: auto;
                    width: rems(64);
                }
            }
        }
    }

    .file_document_name {
        font-size: rems(14);
        overflow: hidden;
        display: block;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;

        input:not([type=submit]):not([type=button]):not([type=checkbox]):not([type=radio]):not([type=range]):not([type=file]) {
            padding: rems(6);
            font-size: rems(14);
            margin-bottom: 0;
        }
    }

    .file_document_actions {
        margin-left: 10px;
        .button {
            margin-bottom: 0;
            margin-right: rems(5);

            + .button {
                margin-left: rems(0);
            }

            &:last-child {
                //margin-bottom: 0;
                margin-right: 0;
            }
        }
    }

    .file_document_scan {
        width: 100%;
        overflow: hidden;
        padding: 0 rems(4);
        ul {
            list-style-type: none;
            padding-left: 0;
        }
        span.count {
            border-radius: rems(4);
            display: flex;
            flex: 0 0 1rem;
            background-color: var(--color-magenta-dark);
            display: inline-block;
            margin: 0;
            width: auto;
            text-transform: uppercase;
            margin: 0 rems(4) 0 0;
            padding: rems(4) rems(6);
            color: var(--color-white);
            font-size: rems(12);
            font-weight: 600;
            line-height: 1;
            letter-spacing: rems(0.25);
            min-width: rems(28);
            text-align: center;
            &.orange {
                background-color: var(--color-orange-dark);
            }
            &.green {
                background-color: var(--color-green-xdark);
            }
        }
    }

    &.small {
        &.file_documents_container {
            margin-bottom: rems(8);
            .file_document_group {
                padding: rems(8);
            }
            .file_document-item {
                + .file_document-item {
                    border-top: 1px solid rgba(0,0,0,0.1);
                    margin-top: rems(8);
                    padding-top: rems(8);
                }
            }
            .file_document_icon {
                margin-right: rems(8);;
                width: 28px;
                height: 28px;
                svg {
                    height: 28px;
                    width: 28px;
                }
                img {
                    height: auto;
                    width: 28px;
                }
            }
            .file_document_title {
                margin-bottom: rems(10);
            }
        }
    }
}

.cropper-container{
    margin-bottom: rems(20);
}


@media (min-width: 26.875rem) {
    .file_documents_container, .cdk-drag-preview {
        .file_document {
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .file_document_group {
            padding: rems(20);
        }

        .file_document_title {
        }

        .file_document-item {
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .file_document_icon {
            display: flex;
            margin-bottom: 0;

            img {
                //height: rems(32);
                width: rems(32);
            }
        }

        .file_document-item {
            &.file_document-image {
                .file_document_icon {

                    + .file_document_actions {
                        margin-left: auto;
                    }
                }
            }
        }

        .file_document_name {
            //display: flex;
            flex: 1 1 35%;
            //margin-right: rems(24);
        }

        .file_document_actions {
            margin-top: rems(0);
            margin-bottom: 0;
            margin-left: rems(10);

            .button {
                margin-bottom: 0;
            }
        }
        &.small {
            //margin-bottom: rems(8);
            .file_document_group {
                padding: rems(8);
            }
            .file_document_icon {
                margin-right: 0;
                img {
                    object-fit: cover;
                    width: rems(36);
                    height: rems(36);
                }
            }
            .file_document_name {
                input:not([type=submit]):not([type=button]):not([type=checkbox]):not([type=radio]):not([type=range]):not([type=file]) {
                    margin-bottom: 0;
                }
            }
        }
    }

    .form--inline .file_documents_container, .form--inline .cdk-drag-preview {
        .file_document_actions {
            .file_document_download {
                &.button.button--small.button--icon {
                    text-indent: rems(999);
                    padding-left: rems(24);
                    max-width: 0;
                    min-width: 0;

                    @include breakpoint(for-small-desktop-up) {
                        text-indent: 0;
                        padding-left: rems(32);
                        min-width: rems(80);
                        max-width: unset;
                    }

                    .icon {
                        text-indent: 0;
                    }
                }
            }
        }
    }

}


span {
    &.extensionType {
        background-color: var(--color-grey);
        border: 1px solid var(--color-grey-dark);
        border-radius: radius(small);
        color: var(--color-white);
        padding: rems(1) rems(4);
        font-weight: 700;
        margin-left: rems(8);
        white-space: nowrap;
        text-transform: uppercase;
        font-size: rems(12);
        line-height: 1.5;
    }
}

.hasExtension {
    display: flex;
    align-items: center;
    span {
        &.extensionType {
            height: rems(20);
            margin-left: rems(12);
        }
    }
}

@include breakpoint(for-tablet-portrait-up){
    .file_documents_container, .cdk-drag-preview {
        .file_document_title {
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            .file_document_actions {
                padding-top: 0;
                display: flex;
            }
        }
    }
}

.cdk-drag-preview {
    box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
    padding-top: 0;
    padding-left: rems(5);
    background: #fff;
    opacity: 0.5;
    display: flex;
    flex-direction: row;
    max-height: rems(34) !important;
    overflow: hidden;

    .file_document_icon {
        img {
            height: rems(32);
            width: rems(32);
        }
    }

    .file_document_name {
        display: none;
    }

    .file_document_actions {
        display: none;
    }
}


.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-custom-placeholder {
    background: #ccc;
    border: dotted 3px #999;
    min-height: 40px;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    margin-top: rems(20);
    width: 100%;
}
