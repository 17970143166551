@import "margins";
@import "padding";
@import "toggle";
@import "hide-show";

.u-border-top {
    border-top: 1px solid var(--color-grey-light);
}

.u-border-bottom {
    border-bottom: 1px solid var(--color-grey-light);
}

.u-border-all {
    border: 1px solid var(--color-grey-light);
}

.u-border-radius--small {
    border-radius: radius(small);
}

.u-border-radius--medium {
    border-radius: radius(medium);
}

.u-align--center {
    align-items: center;
}
