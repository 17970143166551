onboarding-row {
    onboarding-card {
        display: block;
        background: var(--color-white);
        .card__header {
            &.card__header--actions {
                padding-right: 0;

                .card__action-items {
                    min-width: rems(78);
                    padding-right: 0;

                    .button__group {
                        justify-content: flex-end;
                    }
                }
            }

            .button__group-item {
                &:last-of-type {
                    padding-right: 0;
                }
            }

            .button.button--small.button--icon:not(.button--icon-only) {
                font-size: 0;
                text-indent: rems(999);
                padding-left: rems(24);
                max-width: 0;
                min-width: 0;

                @include breakpoint(for-tablet-portrait-up) {
                    font-size: inherit;
                    text-indent: 0;
                    padding-left: rems(32);
                    min-width: rems(80);
                    max-width: unset;
                }

                .icon {
                    text-indent: 0;
                }
            }
        }
        .card__content {
            .card__content--right {
                .card__content--right-toggle {
                    display: none;
                }
            }
        }
    }
}
my-onboardings-panel {
    onboarding-card {
        .card__content {
            .card__content--right {
                .card__content--right-toggle {
                    display: none;
                }
            }
        }
    }
}
onboarding-tenancy-agreement-view {
    rent-editor {
        fieldset {
            padding-top: 0;
        }
    }
}

onboardings {
    .compact__table {
        tr {
            td {
                line-height: 1.2;
            }
            &.summary {
                td.tenant-status {
                    display: flex;
    
                    div {
                        line-height: 1.3;
                        padding: 0 rems(2);
                        height: rems(18);
                        width: rems(18);
                        border-radius: rems(10);
                        border: rems(1) solid var(--color-white);
                        box-shadow: 0 rems(2) rems(10) rgba(0,0,0,0.2);
                        //margin: rems(4) 0 0 0;
    
                        .icon, svg {
                            width: rems(12);
                            height: rems(12);
                        }
    
    
                        &.onboarding--unplugged {
                            background-color: var(--color-grey-light);
                        }
    
                        &.onboarding--invited {
                            background-color: var(--color-orange-dark);
                        }
    
                        &.omnboarding--joined {
                            background-color: var(--color-blue);
                        }
                    }
                }
            }
        }
    }

    tr {
        &.action {
            td {
                &.step {
                    color: var(--color-black);

                    span {
                        &.step {
                            border-radius: rems(4);
                            display: flex;
                            flex: 0 0 1rem;
                            background-color: var(--color-magenta-dark);
                            display: inline-block;
                            margin: 0;
                            width: auto;
                            text-transform: uppercase;
                            margin: 0 rems(4) 0 0;
                            padding: rems(4) rems(6);
                            color: var(--color-white);
                            font-size: rems(10);
                            font-weight: 600;
                            line-height: 1;
                            letter-spacing: rems(0.25);
                        }
                    }
                }
            }
        }

        td {
            &.step {
                color: var(--color-grey);
            }
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    onboarding-row {
        onboarding-card {
            onboarding-history {
                .notes {
                    .notes__container {
                        min-height: rems(320);
                        max-height: rems(560);
                    }
                }
            }
        }
    }
}

@include breakpoint(for-tablet-landscape-up) {
    .compact__table {
        onboarding-row {
            tr.details {
                td {
                    onboarding-card {
                        .card__content {
                            .card__content--left {
                                flex: 0 1 60%;
                            }

                            .card__content--right {
                                flex: 0 1 40%;
                                position: relative; 
                                .card__content--right-toggle {
                                    display: block;
                                    position: absolute;
                                    right: 0;
                                }
                                onboarding-history {
                                    .section__tabs {
                                        .tab-container {
                                            .tabs {
                                                padding-top: rems(6);
                                            }
                                        }
                                    }
                                }
                            }
                            &.right-minimised {
                                .card__content--left {
                                    flex: 0 1 100%;
                                }
    
                                .card__content--right {
                                    flex-basis: rems(51);
                                    .card__content--right-toggle {
                                        position:unset;
                                        display: block;
                                    }
                                    onboarding-history {
                                        display: none;
                                        width: 0;
                                        overflow: hidden;
                                    }
                                    .card__content--right-toggle-text {
                                        p {
                                            font-style: italic;
                                            text-orientation: sideways;
                                            writing-mode: vertical-rl;
                                            font-weight: 400;
                                            margin: 0;
                                            color: var(--color-grey-light);
                                            margin-left: 4px;
                                            span {
                                                font-size: rems(18);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    onboarding-row {
        onboarding-card {
            onboarding-history {
                .notes {
                    .notes__container {
                        max-height: rems(620);
                    }
                }
            }
        }
    }
}
