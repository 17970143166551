.card {
    &--onboarding {
        background: transparent;
        box-shadow: none;
        onboarding-card > .card__header {
            padding: 0 0 0.5rem 0;
        }

        .card__content {
            display: flex;
            flex-direction: column;
            padding: rems(16) 0;

            &--left {
                padding-bottom: rems(20);
            }

            &--right {
                padding-top: rems(8);
                border-top: 1px solid var(--color-grey-xlight);
            }
        }
    }
}

my-room-panel {
    .card {
        &--onboarding {
            onboarding-card > .card__header {
                padding: 0.625rem 0.875rem;
            }
        }
    }
}

onboarding-view {
    form-message .form__message, form-message .form__message:last-child {
        margin-bottom: rems(30);
    }
    application-form-questions form-message .form__message, application-form-questions form-message .form__message:last-child {
        margin-bottom: 0;
    }

    .modal-profile-holder {
        cursor: pointer;
        .expanded-section {
            cursor:default;
        }
        &:hover {
            border-color: var(--color-grey-light);
            box-shadow: 0 rems(1) rems(4) 0 rgba(0,0,0,.15);
        }
    }
    .profile__image {
        box-shadow: 0 rems(2) rems(10) 0 rgba(0,0,0,.15);
    }
    .profile__preview.profile__preview--room-small .profile__image {
        margin-bottom: rems(6);
    }
}



.card__content {
    onboarding-application-form-view {
        p:last-child:not(.step__message-title) {
            margin-bottom: rems(20);
        }
    }
}

onboarding-view {
    .context-item--hidden-content {
        compliancy-item {
            button, .button {
                margin-bottom: rems(10);
            }
        }
    }

    compliancy-item {
        form-message .form__message, form-message .form__message:last-child {
            margin-bottom: rems(20);
            margin-top: 0;
        }
    }
}

onboarding-page {
  .onboarding_archived_banner{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    background-color: color(grey);
    color: color(white);
    border-radius: radius(medium);
  }
}


@include breakpoint(for-tablet-landscape-up) {
    .card {
        &--onboarding {
            background-color: #ffffff;
            box-shadow: 0 rems(2) rems(10) 0 rgba(0,0,0,0.15);

            onboarding-card .card__header {
                padding: 0.625rem 0.875rem;
            }

            .card__content {
                padding: rems(10) rems(14);
                flex-direction: row;

                &--left {
                    flex: 0 1 60%;
                    padding-bottom: 0;
                    max-height: fit-content;
                    //max-width: rems(621);
                }

                &--right {
                    flex: 0 1 40%;
                    padding: 0 rems(8) 0 rems(16);
                    margin-left: rems(16);
                    border-top: 0;
                    border-left: 1px solid var(--color-grey-xlight);
                }
            }
        }
    }
}
