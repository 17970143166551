.form__input-help {
    p {
        color: var(--color-grey);
        font-size: rems(13);
        margin-top: rems(4);
        line-height: 1.4;
        margin-bottom: rems(20);
        &.u-margin-bottom--none {
            margin-bottom: rems(4);
        }
        &.u-text--tiny {
            font-size: rems(12) !important; 
        }
    }


    &.warning {
        color: var(--color-pink);
    }
}

.form__grid{
    + {
        .form__input-help{
            p {
                margin-top: 0;
            }
        }
    }
}

textbox + .form__input-help,
textarea + .form__input-help {
    margin-top: rems(-4);
}
file-uploader + .form__input-help {
    //margin-top: rems(-16);
}
.form__label-title + .form__input-help {
    margin-top: 0;
}


.form__input-info {
    background-color: var(--color-white);
    box-shadow: 0 rems(2) rems(10) 0 rgba(0,0,0,0.15);
    border-radius: radius(medium);
    color: var(--color-text);
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    text-decoration: none;
    transition: box-shadow 0.2s ease-out;
    margin: rems(8) 0;

    .form__input-info--header {
        border-bottom: 1px solid var(--color-grey-xlight);
        display: flex;
        flex: 0 1 auto;
        flex-direction: column;
        padding: rems(8) rems(12);
        width: 100%;
        background-color: var(--color-cyan);
        border-top-left-radius: radius(medium);
        border-top-right-radius: radius(medium);

        h4 {
            font-size: rems(18);
            color: var(--color-white);
        }

        &.form__input-info--header-icon {
            display: flex;
            flex-direction: row;
            align-items: center;


            h4 {
                padding-left: rems(10);
            }

             .icon, .icon svg {
                width: rems(24) !important;
                height: rems(24) !important;
             }
        }
    }

    .form__input-info--content {
        padding: rems(12);
        padding-top: rems(8);
        width: 100%;
    }

    .form__input-info--summary {
        border-top: 1px solid var(--color-grey-xlight);
        padding: rems(10) rems(20);
        width: 100%;
    }

    .form__input-info--content, .form__input-info--summary {
        p {
            font-size: rems(14);
            margin-bottom: rems(10);
            line-height: 1.4;
        }
    }

    p:last-child {
        margin-bottom: 0;
    }
}
