.form__recap--container {
    border: rems(1) solid var(--color-grey-light);
    border-radius: radius(small);
    padding: 0 rems(2) rems(2) rems(2);
    margin-bottom: rems(20);
}

p.form__recap--title {
    font-size: rems(12);
    color: var(--color-grey);
    margin-bottom: rems(2);
    padding-left: rems(2);
}

.form__recap--body {
    display: flex;
    flex-direction: row;
    padding: rems(2);

    border-radius: radius(small);
    cursor: pointer;
    &-no-pointer {
        cursor: default;
    }
    &-border {
        border-top: rems(1) solid var(--color-teal-light);
        padding-top: rems(10);
        margin-top: rems(10);
    }
    .form__recap--image {
        margin-right: rems(8);
        height: rems(24);
        //overflow: hidden;
        .icon, .icon svg {
            width: rems(24); height: rems(24);
        }
    }
    .form__recap--text {
        p {
            margin-bottom: 0;
        }

    }
    &:hover {
        background-color: rgba(var(--color-teal-light-rgb), 0.25);
        .form__recap--text {
            p {
                color: var(--color-teal-dark);
            }
    
        } 
    }
}