.form__message {
    background-color: var(--color-grey-xxlight);
    border-radius: radius(medium);
    display: flex;
    flex-direction: row;
    font-size: rems(14);
    line-height: rems(20);
    margin-bottom: rems(20);
    overflow: hidden;
    width: fit-content;
    border: 1px solid var(--color-grey-light);
    align-items: center;

    &.form__message--border {
        border: 1px solid var(--color-grey-light);
    }

    &.form__message--no-border {
        border: none;
    }
}

form-message {
    .form__message {
        margin-bottom: rems(8);
    }

    &:last-child {
        .form__message {
            margin-bottom: rems(20);
        }
    }
}

form-message + h1 {
    margin-top: rems(20);
}

.room__content-section {
    .form__message {
        margin-bottom: rems(20);
    }
}

.form__message-icon {
    // background-color: var(--color-grey-light);
    padding: rems(10);
    display: flex;
    align-items: flex-start;
    padding-right: rems(16);

    .icon {
        display: block;
        fill: #ffffff;
        height: rems(24);
        width: rems(24);

        svg {
            height: rems(24);
        }
    }
}

.form__message-text {
    padding: rems(10) rems(10) rems(10) rems(0);
    width: 100%;

    .button, .button.button--small, .button.button--tiny {
        margin-bottom: 0;
    }

    .button--space {
        margin-left: rems(16);
    }

    *:last-child {
        margin-bottom: 0;
    }

    ul {
        padding: 0 0 0 rems(16);
        list-style-type: square;
    }
}

.form__message--large{
    border-radius: $size__radius;

    .form__message__text{
        padding: rems(20);
    }
}

.form__message--notification {
    background-color: var(--color-cyan-light);
    border: 1px solid var(--color-cyan);

    .form__message-icon {
        .icon {
            fill: var(--color-cyan-dark);
        }
    }

    &.form__message--notification-white {
        background-color: white;
    }

    &.form__message--border {
        border: 1px solid var(--color-cyan);
    }

    &.form__message--no-border {
        border: none;
    }
}

.form__message--error {
    background-color: var(--color-pink-light);
    border: 1px solid var(--color-pink);

    .form__message-icon {
        .icon {
            fill: var(--color-pink);
        }
    }

    &.form__message--border {
        border: 1px solid var(--color-pink);
    }

    &.form__message--no-border {
        border: none;
    }
}
.form__message--error {
    background-color: var(--color-pink-light);
    border: 1px solid var(--color-pink);

    .form__message-icon {
        .icon {
            fill: var(--color-pink);
        }
    }

    &.form__message--border {
        border: 1px solid var(--color-pink);
    }

    &.form__message--no-border {
        border: none;
    }
}

.form__message--alert {
    background-color: var(--color-pink-light);
    border: 1px solid var(--color-magenta-dark);

    .form__message-icon {
        .icon {
            fill: var(--color-pink-dark);
        }
    }

    &.form__message--border {
        border: 1px solid var(--color-pink-dark);
    }

    &.form__message--no-border {
        border: none;
    }
}

.form__message--success {
    background-color: var(--color-green-light);
    border: 1px solid var(--color-green-xdark);

    .form__message-icon {
        .icon {
            fill: var(--color-green-xdark);
        }
    }

    &.form__message--border {
        border: 1px solid var(--color-green-dark);
    }

    &.form__message--no-border {
        border: none;
    }
}

.form__message--light_bulb {
    background-color: var(--color-orange-light);
    border: 1px solid var(--color-orange-xdark);

    .form__message-icon {
        .icon {
            fill: var(--color-orange-xdark);
        }
    }

    &.form__message--border {
        border: 1px solid var(--color-orange-dark);
    }

    &.form__message--no-border {
        border: none;
    }
}

.form__message--love {
    background-color: var(--color-red-xlight);
    background-color: rgba(var(--color-red-xlight-rgb),0.5);
    border: 1px solid var(--color-red);

    .form__message-icon {
        .icon {
            fill: var(--color-red);
        }
    }

    .form__message-text {
        strong {
           color: var(--color-red-dark);
        }
        span.strong {
            color: var(--color-text);
            font-weight: 800;
        }
    }
    &.form__message--border {
        border: 1px solid var(--color-red);
    }

    &.form__message--no-border {
        border: none;
    }
}

.form__message--confirm {
    background-color: var(--color-green-dark);

    .form__message-icon {
        .icon {
            fill: white;
        }
    }

    &.form__message--border {
        border: 1px solid var(--color-green-xdark);
    }

    .form__message-text {
        color: white;
        font-weight: 700;
    }

    &.form__message--no-border {
        border: none;
    }
}

.form__message--not-invited {
    background-color: var(--color-grey-xlight);

    .form__message-icon {
        .icon {
            fill: var(--color-black);
            height: rems(24);
            width: rems(24);

            svg {
                //margin-top: rems(6);
                height: rems(24);
            }
        }
    }


    .form__message-text {
        padding: rems(10);
    }

    &.form__message--border {
        border: 1px solid var(--color-grey-light);
    }
}
.form__message--invited {
    background-color: var(--color-orange-light);

    .form__message-icon {
        .icon {
            fill: var(--color-orange-dark);
            height: rems(24);
            width: rems(24);

            svg {
                //margin-top: rems(6);
                height: rems(24);
            }
        }
    }

    .form__message-text {
        padding: rems(10);
    }

    &.form__message--border {
        border: 1px solid var(--color-orange-dark);
    }
}
.form__message--joined {
    background-color: var(--color-cyan-light);


    .form__message-icon {
        .icon {
            fill: var(--color-cyan-dark);
            height: rems(24);
            width: rems(24);

            svg {
                //margin-top: rems(6);
                height: rems(24);
            }
        }
    }

    .form__message-text {
        padding: rems(10);
    }

    &.form__message--border {
        border: 1px solid var(--color-cyan);
    }
}

.page__section--alt{
    .form__message{
        border: 1px solid var(--color-grey-light);

        &.form__message--notification{
            border: 1px solid var(--color-cyan);
        }

        &.form__message--error{
            border: 1px solid var(--color-pink);
        }

        &.form__message--success{
            border: 1px solid var(--color-green-xdark);
        }

        &.form__message--alert{
            border: 1px solid var(--color-magenta-dark);
        }
    }
}

.expanded {
    .preview__messages {
        form-message {
            display:none;
        }
    }
}


.form__message--reminder {
    background-color: var(--color-cyan-light);
    max-width: rems(640);
    align-items: center;

    .form__message-action {
        padding: rems(10) rems(20) 0 rems(10);
    }

    &.form__message--border {
        border: 1px solid var(--color-cyan);
    }

    &.form__message--no-border {
        border: none;
    }
}


error-summary, error-response-summary {
    .form__message {
        width: 100%;
        align-items: center;
    }
    .form__message--icon {
        padding-right: rems(20);
    }
    .form__message-text {
        width: 100%;
        p {
            font-size: rems(14);
            line-height: 1.4;
            margin-bottom: 0;
        }
    }
    .form__message-button {
        min-width: fit-content;
        padding: rems(10);
    }
}

.success--blob {
    display: flex;
    padding: 10px;
    margin: 10px;
    gap: 5px;
    align-items: center;
    background-color: var(--color-green-light);
    border: 1px solid var(--color-green);
    border-radius: radius(medium);

    svg-icon {
        height: 20px !important;
        svg {
            fill: var(--color-green);
            height: 20px;
            width: 20px;
        }
    }
}
